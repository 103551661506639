import Head from 'next/head'
import Markdown from 'react-markdown';
import Navbar from '../Navbar'
import Banner from '../Banner'
import Footer from '../Footer'
import { Navlink } from '../../contentful/navlinks.types'
import { Bosskill } from '../../contentful/bosskills.types'
import { SitePage } from '../../contentful/page.types'
import styles from './layout.module.css'
import utilStyles from '../styles/utils.module.css'
import Link from 'next/link'
// @ts-ignore
import { TwitchPlayer, TwitchEmbed } from 'react-twitch-embed'
import Carousel from '../Carousel';
import formatDistanceToNow from 'date-fns/formatDistanceToNow'

export const siteTitle = 'Decidedly Uncouth'

export type LayoutProps = {
  children: React.ReactNode;
  home?: boolean;
  title: string;
  navbar: Navlink[];
  uri: string[] | string | undefined;
  bosskills: Bosskill[],
  blog: SitePage[]
}

export default function Layout(props: LayoutProps) {
  const {
    children,
    home,
    title,
    navbar,
    uri,
    bosskills,
    blog
  } = props;

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="decidedly uncouth, mal'ganis, wow, world of warcraft, raiding, pve, horde, 2 day" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <link rel="apple-touch-icon" sizes="57x57" href="/apple-touch-icon-57x57.png" />
        <link rel="apple-touch-icon" sizes="60x60" href="/apple-touch-icon-60x60.png" />
        <link rel="apple-touch-icon" sizes="72x72" href="/apple-touch-icon-72x72.png" />
        <link rel="apple-touch-icon" sizes="76x76" href="/apple-touch-icon-76x76.png" />
        <link rel="apple-touch-icon" sizes="114x114" href="/apple-touch-icon-114x114.png" />
        <link rel="apple-touch-icon" sizes="120x120" href="/apple-touch-icon-120x120.png" />
        <link rel="apple-touch-icon" sizes="144x144" href="/apple-touch-icon-144x144.png" />
        <link rel="apple-touch-icon" sizes="152x152" href="/apple-touch-icon-152x152.png" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon-180x180.png" />
        <link rel="icon" type="image/png" href="/favicon-32x32.png" sizes="32x32" />
        <link rel="icon" type="image/png" href="/android-chrome-192x192.png" sizes="192x192" />
        <link rel="icon" type="image/png" href="/favicon-96x96.png" sizes="96x96" />
        <link rel="icon" type="image/png" href="/favicon-16x16.png" sizes="16x16" />
        <link rel="manifest" href="/manifest.json" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#000503" />
        <meta name="msapplication-TileImage" content="/mstile-144x144.png" />
        <meta name="theme-color" content="#800000" />
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Lato:300,400,700" />
        <link rel="stylesheet" href="/assets/vendor/bootstrap/dist/css/bootstrap.min.css" />
        <link rel="stylesheet" href="/assets/vendor/flickity/dist/flickity.min.css" />
        <link rel="stylesheet" href="/assets/vendor/magnific-popup/dist/magnific-popup.css" />
        <link rel="stylesheet" href="/assets/vendor/slider-revolution/css/settings.css" />
        <link rel="stylesheet" href="/assets/vendor/slider-revolution/css/layers.css" />
        <link rel="stylesheet" href="/assets/vendor/slider-revolution/css/navigation.css" />
        <link rel="stylesheet" href="/assets/vendor/bootstrap-sweetalert/dist/sweetalert.css" />
        <link rel="stylesheet" href="/assets/vendor/social-likes/dist/social-likes_flat.css" />
        <script defer src="/assets/vendor/fontawesome-free/js/all.js"></script>
        <script defer src="/assets/vendor/fontawesome-free/js/v4-shims.js"></script>
        <link rel="stylesheet" href="/assets/css/youplay.css" />
        <link rel="stylesheet" href="/assets/css/custom.css" />
        <script src="/assets/vendor/jquery/dist/jquery.min.js"></script>
      </Head>

      {/* Preloader  */}
      <div className="page-preloader preloader-wrapp">
        <img src="/assets/images/logo-du.png" alt="" />
        <div className="preloader"></div>
      </div>

      {/* Navbar  */}
      <Navbar data={navbar} uri={uri} />

      <div className="content-wrap">
        {/* Banner */}
        <section className="youplay-banner banner-top youplay-banner-parallax big">
          <div className="image" data-speed="0.4" style={{ opacity: 1 }}>
            <video className="jarallax-img" preload="auto" autoPlay loop playsInline muted style={{ position: 'absolute', left: '50%', "WebkitTransform": 'translateX(-50%)', transform: 'translateX(-50%)', top: 0 }}>
              <source src="/assets/video/df_cinematic_loop.mp4" type="video/mp4" />
            </video>
          </div>
          <div className="info">
            <div>
              <div className="container center" style={{ textAlign: 'center', marginTop: '2rem' }}>
                <h1 className="h1" style={{
                  marginBottom: '1rem',
                  WebkitTextStroke: '1px #000',
                  textShadow: '3px 3px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000'
                }}>Ready to fly?</h1>
                <em style={{ textShadow: '1px 1px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000' }}>Let's ride together.</em>
                <br /><br /><br />
                <a className="btn btn-lg active" href="https://apply.decidedlyuncouth.com">Apply</a>
              </div>
            </div>
          </div>
        </section>

        <Carousel data={bosskills} />

        {/* <h2 className="container h2">Our Streamers <a href="#" className="btn pull-right">See More</a></h2> */}
        {/*
        Carousel

        Additional classes:
            .youplay-carousel-size-1
            .youplay-carousel-size-2
            .youplay-carousel-size-3
            .youplay-carousel-size-4
            .youplay-carousel-size-5
            .youplay-carousel-size-6

        Additional attributes:
            data-autoplay
            data-loop
            data-dots
            data-arrows
            data-stage-padding
            data-item-padding
        */}
        {/*}
        <div className="youplay-carousel youplay-carousel-size-3">
          <a className="angled-img" href="#">
            <div className="img img-offset">
              <TwitchPlayer
                className="twitchIframe"
                channel="preheet"
                id="twitch-preheet"
                autoplay={true}
                muted={true}
                withChat={false}
                width="420px"
                height="315px"
                parent={['www.decidedlyuncouth.com']}
                onOnline={() => console.log('preheet online')} />
            </div>
            <div className="bottom-info">
              <h4>Preheetxd</h4>
            </div>
          </a>
          <a className="angled-img" href="#">
            <div className="img img-offset">
              <TwitchPlayer
                className="twitchIframe"
                channel="suicideskippy"
                id="twitch-suicideskippy"
                autoplay={true}
                muted={true}
                withChat={false}
                width="420px"
                height="315px"
                parent={['www.decidedlyuncouth.com']}
                onOnline={() => console.log('higlet online')} />
            </div>
            <div className="bottom-info">
              <h4>Fsixteen</h4>
            </div>
          </a>
          <a className="angled-img" href="#">
            <div className="img img-offset">
              <TwitchPlayer
                className="twitchIframe"
                channel="cryptsverdict"
                id="twitch-cryptsverdict"
                autoplay={true}
                muted={true}
                withChat={false}
                width="420px"
                height="315px"
                parent={['www.decidedlyuncouth.com']}
                onOnline={() => console.log('higlet online')} />
            </div>
            <div className="bottom-info">
              <h4>Higglet</h4>
            </div>
          </a>
          <a className="angled-img" href="#">
            <div className="img img-offset">
              <TwitchPlayer
                className="twitchIframe"
                channel="justdicegames"
                id="twitch-justdicegames"
                autoplay={true}
                muted={true}
                withChat={false}
                width="420px"
                height="315px"
                parent={['www.decidedlyuncouth.com']}
                onOnline={() => console.log('justdicegames online')}
                onOffline={() => console.log('justdicegames offline')}
              />
            </div>
            <div className="bottom-info">
              <h4>Sacradice</h4>
            </div>
          </a>
          <a className="angled-img" href="#">
            <div className="img img-offset">
              <TwitchPlayer
                className="twitchIframe"
                channel="zstryker"
                id="twitch-zstryker"
                autoplay={true}
                muted={true}
                withChat={false}
                width="420px"
                height="315px"
                parent={['www.decidedlyuncouth.com']}
                onOnline={() => console.log('Ibubbleu online')} />
            </div>
            <div className="bottom-info">
              <h4>Ibubbleu</h4>
            </div>
          </a>
        </div> */}

        <h2 className="container hs">Latest News</h2>
        <section className="youplay-news container">
          {blog.map(blogEntry => {
            return (
              <div key={blogEntry.id} className="news-one">
                <div className="row vertical-gutter">
                  <div className="col-md-4">
                    <a href={'/' + blogEntry.url} className="angled-img">
                      <div className="img">
                        <img src={blogEntry.metaImage !== '' ? blogEntry.metaImage : '/assets/images/du-horde-500-375.jpg'} alt="" />
                      </div>
                    </a>
                  </div>
                  <div className="col-md-8">
                    <div className="clearfix">
                      <h3 className="h2 pull-left m-0"><a href={'/' + blogEntry.url}>{blogEntry.title}</a></h3>
                      <span className="date pull-right"><i className="fa fa-calendar"></i> {formatDistanceToNow(new Date(blogEntry.createdAt))} ago</span>
                    </div>
                    <div className="description">
                      <Markdown
                        source={blogEntry.body}
                        escapeHtml={false}
                        renderers={{
                          link: (p) => {
                            const url = new URL(p.href, 'https://www.decidedlyuncouth.com/');
                            if (!url.hostname.includes('decidedlyuncouth.com')) {
                              return <a href={p.href} rel='nofollow noreferrer noopener' target='_blank'>{p.children}</a>;
                            }

                            return <a href={p.href}>{p.children}</a>;
                          }
                        }}
                      />
                    </div>
                    <a href={'/' + blogEntry.url} className="btn read-more pull-left">Read More</a>
                  </div>
                </div>
              </div>
            );
          })}
        </section>

        {/* Main section */}
        {/* <section className="youplay-content container"> */}
        {children}
        {/* </section> */}

        {/* Footer */}
        <Footer />
      </div>

      <script src="/assets/vendor/object-fit-images/dist/ofi.min.js"></script>
      <script src="/assets/vendor/HexagonProgress/jquery.hexagonprogress.min.js"></script>
      <script src="/assets/vendor/bootstrap/dist/js/bootstrap.min.js"></script>
      <script src="/assets/vendor/jarallax/dist/jarallax.min.js"></script>
      <script src="/assets/vendor/flickity/dist/flickity.pkgd.min.js"></script>
      <script src="/assets/vendor/jquery-countdown/dist/jquery.countdown.min.js"></script>
      <script src="/assets/vendor/magnific-popup/dist/jquery.magnific-popup.min.js"></script>
      <script src="/assets/vendor/slider-revolution/js/jquery.themepunch.tools.min.js"></script>
      <script src="/assets/vendor/slider-revolution/js/jquery.themepunch.revolution.min.js"></script>
      <script src="/assets/vendor/imagesloaded/imagesloaded.pkgd.min.js"></script>
      <script src="/assets/vendor/isotope-layout/dist/isotope.pkgd.min.js"></script>
      <script src="/assets/vendor/bootstrap-validator/dist/validator.min.js"></script>
      <script src="/assets/vendor/bootstrap-sweetalert/dist/sweetalert.min.js"></script>
      <script src="/assets/vendor/social-likes/dist/social-likes.min.js"></script>
      <script src="/assets/js/youplay.min.js"></script>
      <script src="/assets/js/youplay-init.js"></script>

    </>
  )
}