import Head from 'next/head'
import DefaultErrorPage from 'next/error'
import Layout from '../components/layouts/home'
import API from '../contentful/api'
import { Navlink } from '../contentful/navlinks.types'
import { Bosskill } from '../contentful/bosskills.types'
import { SitePage } from '../contentful/page.types'
import { GetStaticProps } from 'next'
import Markdown from 'react-markdown';
import util from "util";

type CmsPageProps = {
  navbar: Navlink[];
  bosskills: Bosskill[];
  blog: SitePage[];
  uri: string[] | string | undefined;
}

export default function HomePage({ navbar, bosskills, uri, blog }: CmsPageProps) {
  // if (!navbar) {
  //   return (
  //     <DefaultErrorPage statusCode={404} />
  //   );
  // }

  return (
    <Layout title="Home" navbar={navbar} uri={uri} bosskills={bosskills} blog={blog}>
      <Head>
        <title>Decidedly Uncouth</title>
      </Head>
      {/* BossKills */}
    </Layout>
  );
}

export const getStaticProps: GetStaticProps = async (context) => {
  const api = new API();

  try {
    let navbar = await api.fetchNavbar();
    let bosskills = await api.fetchBossKills();
    let blog = await api.fetchRecentBlogPosts();
    // console.log(util.inspect(blog, false, null, true /* enable colors */));

    return {
      props: { navbar, bosskills, blog, uri: '' },
      revalidate: 300 // 5 minutes
    };
  } catch (err) {
    console.error(err.message);
    return { props: { navbar: false } };
  }
}
