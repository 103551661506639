import React from "react";
import { Bosskill } from '../contentful/bosskills.types'

export type CarouselProps = {
  data: Bosskill[];
};

export const Carousel = ({ data }: CarouselProps) => {

  /*
  Carousel

  Additional classes:
      .youplay-carousel-size-1
      .youplay-carousel-size-2
      .youplay-carousel-size-3
      .youplay-carousel-size-4
      .youplay-carousel-size-5
      .youplay-carousel-size-6

  Additional attributes:
      data-autoplay
      data-loop
      data-dots
      data-arrows
      data-stage-padding
      data-item-padding
  */

  let itemCount = 0;

  const carouselItems = data.map(item => {
    itemCount++;
    return (
      <a key={item.name} className="angled-img">
        <div className="img">
          <img src={item.image} style={{ opacity: 0.8 }} loading={itemCount > 4 ? 'lazy' : 'eager'} alt="" />
        </div>
        <div className="over-info">
          <div>
            <div>
              <h4 className="h4">{item.name}</h4>
              <div>{item.date}</div>
              <div>{item.text1}</div>
              <div>{item.text2}</div>
            </div>
          </div>
        </div>
      </a>
    )
  });

  return (
    <div className="youplay-carousel" data-autoplay="5000" data-loop="true">
      {carouselItems}
    </div>
  );
};


export default Carousel;